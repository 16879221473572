<template>
  <v-dialog v-model="isShowVerifyModal" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-on="on" v-bind="attrs">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card>
        <v-toolbar class="elevation-0" color="transparent">
          <v-btn fab color="gray lighten-5" small class="elevation-0 mr-3">
            <v-icon color="gray">mdi-check-outline</v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold">
            {{ $_t("attribute.confirm") }}
          </span>
          <v-spacer></v-spacer>
          <div class="d-flex flex-column gap-2">
            <span>
              {{
                `${$_t("attribute.confirmedBy")}: ${
                  confirmationDetail.confirmedBy || $_t("attribute.nobody")
                }`
              }}
            </span>
            <span>
              {{
                `${$_t("attribute.mainConfirmedBy")}: ${
                  confirmationDetail.mainConfirmedBy || $_t("attribute.nobody")
                }`
              }}
            </span>
          </div>

          <v-btn icon @click="cancel">
            <v-icon color="gray">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <MainSoftForm v-if="form">
          <template v-slot:body>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-switch
                    hide-details
                    v-model="form.confirmed"
                    flat
                    :label="$_t('attribute.ACConfirmation')"
                  ></v-switch>
                </v-col>
                <v-col cols="6" class="d-flex justify-start align-center">
                  <v-textarea
                    hide-details
                    outlined
                    readonly
                    :label="$_t('attribute.description')"
                    v-model="transfer.description"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                    hide-details
                    :readonly="!isFinancial"
                    v-model="form.mainConfirmed"
                    flat
                    :label="$_t('attribute.FAConfirmation')"
                  ></v-switch>
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    :readonly="!isFinancial"
                    hide-details
                    outlined
                    :label="$_t('attribute.FAMessage')"
                    v-model="form.confirmationMessage"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </MainSoftForm>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel"> {{ $_t("attribute.cancel") }} </v-btn>
          <v-btn
            color="green darken-1"
            :disabled="canAcademicChange"
            dark
            @click="confirm"
          >
            {{ $_t("attribute.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import { mapGetters } from "vuex";
export default {
  name: "ConfirmTransfer",
  components: { MainSoftForm },
  props: {
    transfer: {
      default: null,
      required: true,
    },
    confirmationDetail: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      role: "authentication/role",
    }),
    isFinancial() {
      return ["Admin", "FinancialAdmin"].includes(this.role);
    },
    canAcademicChange() {
      return (
        ["AcademicConsultant"].includes(this.role) &&
        this.confirmationDetail.confirmed
      );
    },
  },
  data() {
    return {
      isShowVerifyModal: false,
      form: {
        mainConfirmed: false,
        confirmed: false,
        confirmationMessage: "",
        confirmedBy: null,
      },
    };
  },
  watch: {
    isShowVerifyModal(value) {
      if (value) {
        console.log(this.confirmationDetail);
        this.form = { ...this.confirmationDetail };
      } else {
        this.form = {
          mainConfirmed: false,
          confirmed: false,
          confirmationMessage: "",
          confirmedBy: null,
        };
      }
    },
  },
  methods: {
    open() {
      this.isShowVerifyModal = true;
    },
    cancel() {
      this.isShowVerifyModal = false;
    },
    async confirm() {
      let res = null;
      if (this.role === "Admin") {
        res = await this.$actions.transferConfirmedByConsultant({
          transferId: this.transfer.transferId,
          shouldConfirm: this.form.confirmed,
        });
        res = await this.$actions.transferConfirmedByFinancialAdmin({
          transferId: this.transfer.transferId,
          shouldConfirm: this.form.confirmed,
          shouldMainConfirm: this.form.mainConfirmed,
          confirmationOrRevertConfirmationMessage:
            this.form.confirmationMessage,
        });
      } else if (this.role === "AcademicConsultant") {
        res = await this.$actions.transferConfirmedByConsultant({
          transferId: this.transfer.transferId,
          shouldConfirm: this.form.confirmed,
        });
      } else {
        res = await this.$actions.transferConfirmedByFinancialAdmin({
          transferId: this.transfer.transferId,
          shouldConfirm: this.form.confirmed,
          shouldMainConfirm: this.form.mainConfirmed,
          confirmationOrRevertConfirmationMessage:
            this.form.confirmationMessage,
        });
      }
      if (res) {
        this.isShowVerifyModal = false;
        return this.$emit("confirm");
      }
    },
  },
};
</script>
